export default class Util {
	static map(x, a, b, c, d) {
		return (x - a) * (d - c) / (b - a) + c;
	}

	static lerp(a, b, n) {
		return (1 - n) * a + n * b;
	}

	static easeOutQuad(t) {
		return t * (2 - t);
	}

	static clamp(num, min, max) {
		return Math.max(min, Math.min(num, max));
	}

	static getScroll() {
		return window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
	}

	static isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const winHeight = window.innerHeight;

		return rect.top >= -rect.height && rect.top <= winHeight;
	}
}