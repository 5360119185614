export default class Waves {
	constructor(el) {
		this.canvas = el;
		this.config = {
			inc: 0, // increment
			amp: 12, // amplitude
			offsetX: 200
		};

		this.animate = this.animate.bind(this);
	}

	init() {
		if (this.canvas) {
			this.canvasRect = this.canvas.getBoundingClientRect();
			this.c = this.canvas.getContext('2d');
			this.c.globalAlpha = 1;
			this.canvas.width = this.canvasRect.width;
			this.canvas.height = this.canvasRect.height;
			this.animate();
		}
	}

	render() {
		this.c.beginPath();
		this.c.moveTo(0, this.canvasRect.height / 2 - 20);

		for (let i = 0; i < this.canvasRect.width + this.config.offsetX; i++) {
			this.c.lineTo(
				i - this.config.offsetX / 2, // x
				(this.canvasRect.height / 2 - 10) + Math.sin(i * 0.05 + this.config.inc) * this.config.amp * Math.sin(this.config.inc * 0.5) // y
			);
		}

		this.c.lineWidth = 50;
		this.c.strokeStyle = '#d1f0fb';
		this.c.stroke();
		this.c.closePath();

		//////

		this.c.beginPath();
		this.c.moveTo(0, this.canvasRect.height);

		for (let i = 0; i < this.canvasRect.width + this.config.offsetX; i++) {
			this.c.lineTo(
				i - this.config.offsetX / 2, // x
				this.canvasRect.height + Math.sin(i * 0.05 + this.config.inc) * this.config.amp * 1.2 * Math.sin(this.config.inc) // y
			);
		}
		this.c.lineWidth = 50;
		this.c.strokeStyle = '#f4f5f5';
		this.c.stroke();
		this.c.closePath();

		this.config.inc += 0.05;
	}

	animate() {
		this.c.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
		this.render();
		requestAnimationFrame(this.animate);
	}
}