import '../scss/style.scss';

import 'svgxuse';
import smoothscroll from 'smoothscroll-polyfill';

import Menu from './modules/Menu';
import Dropdown from './modules/Dropdown';
import LetterSplit from './modules/LetterSplit';
import AnimationController from './modules/AnimationController';
import HoverController from './modules/HoverController';
import Sliders from './modules/Sliders';
import Overlay from './modules/Overlay';
import Anchor from './modules/Anchor';
import Waves from './modules/Waves';
import ReadMore from './modules/ReadMore';
import Form from './modules/Form';
import DeferredImage from './modules/DeferredImage';

class App {
	constructor() {
		this.onDocumentReady = this.onDocumentReady.bind(this);
		this.onWindowLoaded = this.onWindowLoaded.bind(this);
	}

	init() {
		document.addEventListener('DOMContentLoaded', this.onDocumentReady);
		window.addEventListener('load', this.onWindowLoaded);
	}

	onDocumentReady() {
		smoothscroll.polyfill();
		this.initMenu();
		this.initSliders();
		this.initAnchors();
		this.initOverlay();
		this.initAnimation();
		this.initProductCanvas();
		this.initReadMore();
		this.initForm();
	}

	onWindowLoaded() {
		document.body.classList.remove('loading');
		this.initDropdowns();
		this.initHoverEffect();
		this.loadDeferredImages();
	}

	initSliders() {
		Sliders.initAll();
	}

	initMenu() {
		const menuEl = document.querySelector('[data-menu="el"]');
		const menu = new Menu(menuEl);
		menu.init();
	}

	initAnchors() {
		const anchorList = document.querySelectorAll('[data-anchor="el"]');
		anchorList.forEach(el => new Anchor(el));
	}

	initOverlay() {
		const overlay = new Overlay();
		overlay.init();
	}

	initDropdowns() {
		const dropdownEls = document.querySelectorAll('[data-dropdown="el"]');
		dropdownEls.forEach(el => {
			const dropdown = new Dropdown(el);
			dropdown.init();
		});
	}

	initAnimation() {
		const splitTextEls = document.querySelectorAll('[data-split="el"]');
		splitTextEls.forEach(el => {
			const splitText = new LetterSplit(el);
			splitText.init();
		});

		const animController = new AnimationController();
		animController.init();
	}

	initHoverEffect() {
		const hoverController = new HoverController();
		hoverController.init();
	}

	initProductCanvas() {
		const productCanvas = document.querySelector('.g-mainvisual__canvas');
		const waves = new Waves(productCanvas);
		waves.init();
	}

	initReadMore() {
		const readMoreEl = document.querySelector('[data-readmore="el"]');
		const readMore = new ReadMore(readMoreEl);
		readMore.init();
	}

	loadDeferredImages() {
		const deferredImageEls = document.querySelectorAll('[data-deferred-img], [data-deferred-bg]');
		deferredImageEls.forEach(el => {
			const imageObj = new DeferredImage(el);
			imageObj.init();
		});
	}

	initForm() {
		const formEl = document.querySelector('[data-form="el"]');
		const form = new Form(formEl);
		form.init();
	}
}

const app = new App();
app.init();