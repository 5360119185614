import Swiper from '../lib/swiper.min.js';

export default class Sliders {
	static initAll() {
		this.initHeroSlider();
		this.initQualitySlider();
		this.initDetailSlider();
	}

	static initHeroSlider() {
		return new Swiper('.g-hero__slidecontainer.swiper-container', {
			loop: true,
			speed: 1000,
			effect: 'fade',
			autoplay: {
				delay: 3000,
				disableOnInteraction: false
			}
		});
	}

	static initQualitySlider() {
		const qualityImageboxEls = document.querySelectorAll('.lp-block__imagebox');

		qualityImageboxEls.forEach(el => {
			const qualitySlider = el.querySelector('.lp-block__slider');

			if (qualitySlider) {
				const controlNext = el.querySelector('.lp-block__next');
				const controlPrev = el.querySelector('.lp-block__prev');

				new Swiper(qualitySlider, {
					loop: true,
					speed: 1000,
					autoplay: {
						delay: 2000,
						disableOnInteraction: false
					},
					navigation: {
						nextEl: controlNext,
						prevEl: controlPrev
					}
				});
			}
		});
	}

	static initDetailSlider() {
		const detailImageboxEls = document.querySelectorAll('.d-var__imagebox');

		detailImageboxEls.forEach(el => {
			const detailSlider = el.querySelector('.d-var__slider');

			if (detailSlider) {
				const paginationEl = el.querySelector('.d-var__pagination');

				new Swiper(detailSlider, {
					loop: true,
					speed: 1000,
					autoplay: {
						delay: 2000,
						disableOnInteraction: false
					},
					pagination: {
						el: paginationEl
					}
				});
			}
		});
	}
}