export default class ReadMore {
	constructor(el) {
		this.DOM = { el: el };
	}

	init() {
		if (this.DOM.el) {
			this.truncateText();
		}
	}

	truncateText() {
		this.textLength = this.DOM.el.innerText.length;

		if (this.textLength > 120) {
			const textStart = this.DOM.el.innerText.substring(0, 120)
			const textEnd = this.DOM.el.innerText.substring(120, this.textLength);

			this.DOM.el.innerHTML = '';

			const textWrap = document.createElement('div');
			textWrap.innerHTML = textStart;
			textWrap.style.overflow = 'hidden';
			this.DOM.el.appendChild(textWrap);

			const fullText = document.createElement('span');
			fullText.innerHTML = `${textEnd}<br>`;
			textWrap.appendChild(fullText);

			const readMore = document.createElement('a');
			readMore.innerText = 'もっと読む';
			readMore.setAttribute('href', '#');
			readMore.classList.add('read-more');
			this.DOM.el.appendChild(readMore);

			const readLess = document.createElement('a');
			readLess.innerText = '閉じる';
			readLess.setAttribute('href', '#');
			readLess.classList.add('read-less');
			this.DOM.el.appendChild(readLess);

			const wrapHeight = textWrap.offsetHeight;
			textWrap.style.transition = 'max-height 500ms';
			textWrap.style.maxHeight = `${wrapHeight - fullText.offsetHeight}px`;

			readMore.addEventListener('click', e => {
				e.preventDefault();
				textWrap.style.maxHeight = `${wrapHeight}px`;
				e.currentTarget.style.visibility = 'hidden';
				e.currentTarget.style.opacity = 0;

				readLess.style.visibility = 'visible';
				readLess.style.opacity = 1;
			});

			readLess.addEventListener('click', e => {
				e.preventDefault();
				textWrap.style.maxHeight = `${wrapHeight - fullText.offsetHeight}px`;
				e.currentTarget.style.visibility = 'hidden';
				e.currentTarget.style.opacity = 0;
				readMore.style.visibility = 'visible';
				readMore.style.opacity = 1;
			});
		}
	}
}