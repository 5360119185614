export default class DeferredImage {
	constructor(el) {
		this.DOM = { el: el };
	}

	init() {
		if (this.DOM.el) {
			this.loadImage();
		}
	}

	loadImage() {
		const isImage = !!this.DOM.el.getAttribute('data-deferred-img');
		const imageSrc = this.DOM.el.getAttribute(isImage ? 'data-deferred-img' : 'data-deferred-bg');

		if (isImage) {
			this.DOM.el.style.height = `${this.DOM.el.style.offsetHeight}px`;
			this.DOM.el.setAttribute('src', imageSrc);
			this.DOM.el.style.height = `auto`;
		}
		else {
			this.DOM.el.style.backgroundImage = `url(${imageSrc})`;
		}
	}
}