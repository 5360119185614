export default class Form {
	constructor(el) {
		this.DOM = { el: el };

		this.onFaxBtnClick = this.onFaxBtnClick.bind(this);
		this.onRecalcClick = this.onRecalcClick.bind(this);
	}

	init() {
		if (this.DOM.el) {
			this.getChildren();
			this.bindEvents();
		}
	}

	getChildren() {
		this.DOM.phoneField = this.DOM.el.querySelector('[data-form="tel"]');
		this.DOM.faxField = this.DOM.el.querySelector('[data-form="fax"]');
		this.DOM.faxBtn = this.DOM.el.querySelector('[data-form="faxBtn"]');
		this.DOM.recalcBtns = this.DOM.el.querySelectorAll('[data-form="recalc"]');
	}

	bindEvents() {
		this.DOM.faxBtn.addEventListener('click', this.onFaxBtnClick);
		this.DOM.recalcBtns.forEach(el => el.addEventListener('click', this.onRecalcClick));
	}

	onFaxBtnClick(e) {
		e.preventDefault();
		const phoneVal = this.DOM.phoneField.value.replace(/\s/g, '');
		this.DOM.faxField.value = phoneVal;
	}

	onRecalcClick(e) {
		e.preventDefault();

		const parentEl = e.currentTarget.closest('[data-form-price]');
		const productPrice = parseInt(parentEl.getAttribute('data-form-price'));
		const quantityEl = parentEl.querySelector('[data-form="quantity"]');
		const quantityVal = parseInt(quantityEl.value);
		const priceEl = parentEl.querySelector('[data-form="price"]');

		if (quantityVal > 0) {
			const newPrice = (quantityVal * productPrice).toLocaleString('ja-JP', {
				style: 'currency',
				currency: 'JPY'
			});
			priceEl.innerHTML = newPrice;
		}
	}
}