export default class Overlay {
	constructor() {
		this.DOM = {
			el: document.querySelector('[data-overlay="el"]'),
			triggerEls: document.querySelectorAll('[data-overlay="trigger"]')
		};

		this.onTriggerClick = this.onTriggerClick.bind(this);
		this.onCloseClick = this.onCloseClick.bind(this);
		this.onOverlayBackgroundClick = this.onOverlayBackgroundClick.bind(this);
	}

	init() {
		if (this.DOM.el && this.DOM.triggerEls.length) {
			setTimeout(() => this.DOM.el.style.display = 'flex', 500);
			this.getChildren();
			this.bindEvents();
		}
	}

	getChildren() {
		this.DOM.close = this.DOM.el.querySelector('.g-overlay__btn');
		this.DOM.iframe = this.DOM.el.querySelector('.g-overlay__content iframe');
		this.DOM.image = this.DOM.el.querySelector('.g-overlay__content img');
	}

	bindEvents() {
		this.DOM.triggerEls.forEach(el => el.addEventListener('click', this.onTriggerClick));
		this.DOM.close.addEventListener('click', this.onCloseClick);
		this.DOM.el.addEventListener('click', this.onOverlayBackgroundClick);
	}

	onOverlayBackgroundClick(e) {
		const currentTarget = e.target;
		if (currentTarget === this.DOM.el) {
			this.onCloseClick(e)
		};
	}

	onTriggerClick(e) {
		e.preventDefault();
		const dataUrl = e.currentTarget.getAttribute('data-overlay-url'); // url for iframe
		const dataSrc = e.currentTarget.getAttribute('data-overlay-src'); // src for image

		if (dataUrl) {
			this.DOM.iframe.setAttribute('src', dataUrl);
			this.DOM.iframe.style.display = 'block';
		}
		else {
			this.DOM.image.setAttribute('src', dataSrc);
			this.DOM.image.style.display = 'block';
		}

		this.DOM.el.classList.add('is-open');
	}

	onCloseClick(e) {
		e.preventDefault();
		this.DOM.el.classList.remove('is-open');

		setTimeout(() => {
			[this.DOM.iframe, this.DOM.image].forEach(el => {
				el.setAttribute('src', '');
				el.style.display = 'none';
			});
		}, 500);
	}
}