export default class Dropdown {
	constructor(el) {
		this.DOM = { el: el };
		this.state = {
			isOpen: false,
			bodyHeight: null
		};

		this.onClick = this.onClick.bind(this);
	}

	init() {
		if (!this.DOM.el) return;
		this.getChildren();
		this.getInitialHeight();
		this.bindEvents();
	}

	getChildren() {
		this.DOM.head = this.DOM.el.querySelector('[data-dropdown="head"]');
		this.DOM.body = this.DOM.el.querySelector('[data-dropdown="body"]');
	}

	getInitialHeight() {
		this.state.bodyHeight = this.DOM.body.scrollHeight;
	}

	bindEvents() {
		this.DOM.head.addEventListener('click', this.onClick);
	}

	onClick(e) {
		e.preventDefault();
		this.state.isOpen ? this.hideContent() : this.showContent();
	}

	showContent() {
		this.state.isOpen = true;
		this.DOM.el.classList.add('is-open');
		this.DOM.body.style.maxHeight = `${this.state.bodyHeight}px`;
	}

	hideContent() {
		this.state.isOpen = false;
		this.DOM.el.classList.remove('is-open');
		this.DOM.body.style.maxHeight = '0px';
	}
}