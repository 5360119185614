export default class Anchor {
	constructor(el) {
		this.DOM = { el : el };

		this.onClick = this.onClick.bind(this);
		this.init();
	}

	init() {
		if (!this.DOM.el) return;
		this.bindEvents();
	}

	bindEvents() {
		this.DOM.el.addEventListener('click', this.onClick);
	}

	getOffset(el) {
		let offset = 0;

		do {
			offset += el.offsetTop || 0;
			el = el.offsetParent;
		} while(el);

		return offset;
	}

	onClick(e) {
		const href = this.DOM.el.getAttribute('href');
		this.DOM.target = document.querySelector(href);

		if (this.DOM.target) {
			e.preventDefault();

			const offsetTop = this.getOffset(this.DOM.target);

			setTimeout(() => {
				window.scrollTo({ top: offsetTop, behavior: 'smooth' });
			}, 10);
		}

	}
}