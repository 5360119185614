import throttle from 'lodash/throttle';
import Util from './Util';

export default class HoverController {
	constructor() {
		this.hoverAreaEls = document.querySelectorAll('[data-hover="el"]');
		this.hoverAreaObjs = Array.from(this.hoverAreaEls).map(el => new HoverArea(el));
		this.isAnimating = false;
		this.isMobile = window.innerWidth < 768;

		this.animate = this.animate.bind(this);
	}

	init() {
		if (this.isMobile) return;
		this.hoverAreaObjs.forEach(obj => obj.init());
		this.animate();
	}

	animate() {
		if (!this.isAnimating) {
			this.isAnimating = true;

			this.hoverAreaObjs.forEach(obj => obj.setTransform());

			this.isAnimating = false;
			requestAnimationFrame(this.animate);
		}
	}
}

class HoverArea {
	constructor(el) {
		this.DOM = { el: el };
		this.state = {
			elWidth: null,
			elHeight: null,
			elLeft: null
		};
		this.mouse = { x: 0, y: 0 };
		this.transform = {
			x: 0,
			y: 0,
			coef: []
		};

		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.setTransform = this.setTransform.bind(this);
	}

	init() {
		if (this.DOM.el) {
			this.getContainerRect();
			this.getHoverTargets();
			this.getTransformCoefficient();
			this.bindEvents();
		}
	}

	getHoverTargets() {
		this.DOM.targetEls = this.DOM.el.querySelectorAll('[data-hover="target"]');
	}

	getTransformCoefficient() {
		this.DOM.targetEls.forEach(el => {
			const coefficient = parseFloat(el.getAttribute('data-hover-coef')) || 0.025;
			this.transform.coef.push(coefficient);
		});
	}

	getContainerRect() {
		const rect = this.DOM.el.getBoundingClientRect();
		this.state.elWidth = rect.width;
		this.state.elHeight = rect.height;
		this.state.elLeft = rect.left;
	}

	bindEvents() {
		this.DOM.el.addEventListener('mousemove', throttle(this.onMouseMove, 32));
		this.DOM.el.addEventListener('mouseleave', this.onMouseLeave);
	}

	onMouseMove(e) {
		this.mouse.x = e.clientX - this.state.elLeft - (this.state.elWidth / 2);
		this.mouse.y = e.clientY - this.state.elHeight / 2;
	}

	onMouseLeave() {
		this.mouse.x = 0;
		this.mouse.y = 0;
	}

	setTransform() {
		this.transform.x = Util.lerp(this.transform.x, this.mouse.x, 0.1);
		this.transform.y = Util.lerp(this.transform.y, this.mouse.y, 0.1);
		const x = parseFloat(this.transform.x.toFixed(2));
		const y = parseFloat(this.transform.y.toFixed(2));

		this.DOM.targetEls.forEach((el, index) => {
			el.style.transform = `translate3d(
				${x * this.transform.coef[index]}px,
				${y * this.transform.coef[index]}px,
				0
			)`;
		});
	}
}