export default class Menu {
	constructor(el) {
		this.DOM = { el: el };
		this.state = {
			isOpen: false
		};

		this.onTriggerClick = this.onTriggerClick.bind(this);
		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	init() {
		if (this.DOM.el) {
			this.getChildren();
			this.bindEvents();
			setTimeout(() => {
				this.DOM.el.style.visibility = 'visible';
				this.DOM.background.style.display = 'block';
			}, 500);
		}
	}

	getChildren() {
		this.DOM.trigger = document.querySelector('[data-menu="trigger"]');
		this.DOM.background = document.querySelector('[data-menu="background"]');
	}

	bindEvents() {
		this.DOM.trigger.addEventListener('click', this.onTriggerClick);
		this.DOM.background.addEventListener('click', this.closeMenu);
	}

	onTriggerClick(e) {
		e.preventDefault();
		this.state.isOpen ? this.closeMenu() : this.openMenu();
	}

	openMenu() {
		this.state.isOpen = true;
		this.DOM.el.classList.add('is-open');
		this.DOM.trigger.classList.add('is-active');
	}

	closeMenu() {
		this.state.isOpen = false;
		this.DOM.el.classList.remove('is-open');
		this.DOM.trigger.classList.remove('is-active');
	}
}