export default class LetterSplit {
	constructor(el) {
		this.DOM = { el: el };
		this.state = {
			fontSize: null
		};
	}

	init() {
		if (this.DOM.el) {
			this.splitText();
		}
	}

	splitText() {
		const letterList = this.DOM.el.innerHTML.split('<br>');
		let delayIndex = 0;
		this.state.fontSize = window.getComputedStyle(this.DOM.el).getPropertyValue('font-size');

		this.DOM.el.innerText = '';
		this.DOM.el.style.fontSize = '0';

		letterList.forEach(line => {
			const lineEl = document.createElement('span');
			lineEl.classList.add('split-line');

			Array.from(line).forEach(letter => {
				const letterEl = document.createElement('span');
				letterEl.style.fontSize = this.state.fontSize;
				letterEl.style.transitionDelay = `${delayIndex++ * 50}ms`;
				letterEl.classList.add('split-letter');
				letterEl.innerHTML = letter;
				lineEl.appendChild(letterEl);
			});

			this.DOM.el.appendChild(lineEl);
		});
	}
}